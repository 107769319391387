.trackerBlock {
    border-radius: 13px;
    box-sizing: border-box;
    /* background-color: #E9E9E9; */
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: 10px auto;
    display: table;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.biggestExpense{
    color: #000000;
    font-size: 30px;
    margin:0 0 10px;
    font-weight: bold;
    text-shadow: 0px 5px 3px #200a0a;
}

.welcome {
    color: #000000;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    margin: 0;
    text-shadow: 0px 5px 3px #ece5e5;
}
.totalMoney {
    color: #000000;
    font-size: 30px;
    margin:0 0 10px;
    font-weight: bold;
    text-shadow: 0px 5px 3px #f4e7e7;
}

.newTransaction {
    box-sizing: border-box;
    text-align: center;
    color: #000000;
    display: table;
    width: 100%;
    border-radius: 13px;
    background-color: #fff;
    border: 0;
    padding: 10px;
    margin:0 0 10px;
    font-size: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.newTransaction input[type="text"],
.newTransaction select{
    margin: 10px 0 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 13px;
    border: 1px solid #949090;
    font-size: 13px;
    padding: 7px;
}

.newTransaction input[type="text"]:focus,
.newTransaction select:focus{
    outline: none;
}

.newTransaction input[type="date"]{
    margin: 10px 0 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 13px;
    border: 1px solid #949090;
    font-size: 13px;
    padding: 7px;
}

.indate{
    padding-top:4px;
    margin: 10px 0 0;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #F8F7F7;
    font-size: 13px;
    border-radius: 13px;
}

.inputGroup {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.addTransaction {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    border-radius: 13px;
    background-color: #f33458;
    border: 0;
    margin: 30px 0 0;
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
}

.addTransaction:hover {
    background-color: #F7F8FC;
}

.addTransaction:focus{
    outline: none;
}



.latestTransactions {
    margin: 10px 0 0 0;
    background-color: #fff;
    padding: 10px;
    border-radius: 13px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.latestTransactions p{
    font-weight: bold;
    padding: 0 0 5px;
    margin: 0;
    font-size: 13px;
    border-bottom: 1px solid #ddd;
}
.latestTransactions #uid{
    width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul li {
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
}

ul li:last-child{
    border-bottom: 0;
    padding: 5px 0 0;
}

.expense {
    color: red;
}

.deposit {
    color: green;
}

.exit {
    background-color: #fff;
    font-weight: bold;
    border: 0;
    padding: 8px;
    border-radius: 13px;
    color: #f33458;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.exit:hover {
    background-color: #f1f1f1;
}
