.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #f33458;
    color: rgb(255, 255, 255);
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }

  .brand-name {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
    margin-left: 1rem;
  }
.navigation-menu {
    margin-left: auto;
  }

.navigation-menu ul {
    display: flex;
    padding: 0;
  }
.navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
.navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 77px);
      background-color: #f33458;
      border-top: 1px solid black;
    }
    .navigation-menu li {
      
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: rgb(255, 255, 255);
      font-weight: bold;
      
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #f33458;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
    }
  }

.navigation-menu li a {
    color: rgb(255, 255, 255);
    font-weight: bold;
}