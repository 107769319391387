.mainBlock {
  border-radius: 12px;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  margin: 100px auto;
  display: table;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Error{
    padding: 10px;
    background-color: #feeaea;
    border: 1px solid #F7F8FC;
    margin-bottom: 10px;
    border-radius: 3px;
  }

  .Spinner{
    display: table;
    margin: 0 auto;
  }
  .Spinner > .ImgSpinner {
    max-width: 30px;
  }