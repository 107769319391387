.regField {
    border-radius: 12px;
    width: 100%;
    background-color: #f7f7f7;
    color: #000;
    border: 5px solid #fff;
    padding: 15px;
    box-sizing: border-box;
    font-size: 15px;
}

.submitBtn {
    border-radius: 12px;
    width: 100%;
    background: #f33458;
    border: 0;
    color: #fff;
    padding: 15px;
    margin: 10px 0 0 0;
    font-size: 15px;
    cursor: pointer;
}
.underLine {
    text-align: center;
    margin: 7px 0 0 0;
    display: block;
}

.linkBtn {
    border: 0;
    background-color: unset;
    text-decoration: underline;
    color: #3c4043;
    padding: 0;
    margin: 0;
}

.linkBtn:hover {
    color: blue;
    cursor: pointer;
}

.linkBtn:focus,
.submitBtn:focus{
    outline: none;
}

.showPasswordBtn{
    margin-left: 285px;
    background: none;
    border: none;
}