.uid{
    font-size: 15px;
    color: black;
}
.date{
    font-size: 15px;
    color: black;
}
.container{
    display: flex;
}
.Thead{
    font-size: 20px;
    color: black;
    font-weight: bold;
}